<template>
    <div class="menu" id="dataDictionary">

        <div class="menu_content">
            <div class="menu_content_title">
                <h4>数据字典</h4>
            </div>

            <div class="menu_content_content">
                <div class="menu_left">
                    <el-input @input="filterTextChangeHandler" placeholder="搜索字典名称" v-model="filterText" suffix-icon="el-icon-search" style="height: 32px; margin-bottom: 20px"></el-input>
                    
                    <div class="dictionary_list" :class="chooseDiction.id == item.id ? 'chooseDic' : ''" @click="handleClick(item)" v-for="(item,index) in leftData" :key="index"><span class="el-icon-collection"></span>{{item.dictText}}</div>

                </div>

                <div class="menu_right">
                    <div>
                        <el-button type="primary" @click="addDiction" style="height: 32px; padding: 0 12px">新增字典</el-button>
                    </div>
                    
                    <h6 v-if="isShow">{{chooseDiction.dictText}} <a @click="addKey">新增字典值</a><a @click="editDiction">编辑</a></h6>

                    <p v-if="isShow"> <span>字典项：{{tableData.length}}</span></p>
                    
                    <div style="margin-top: 14px">
                        <el-table border :data="tableData">
                            <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column prop="dictValueCode" label="字典编码"></el-table-column>
                            <el-table-column prop="dictValueText" label="字典值"></el-table-column>

                            <!-- <el-table-column prop="name" label="字典描述"></el-table-column> -->
                            <el-table-column prop="sort" label="排序"></el-table-column>
                            <el-table-column prop="dictValueStatus" align="center" label="字典状态">
                                <template slot-scope="scope">
                                    <el-switch
                                        @change="changeStatus(scope.row)"
                                        v-model="scope.row.dictValueStatus == 1 ? true : false"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                    </el-switch>

                                </template>
                            </el-table-column>
                           
                            <el-table-column prop="name" fixed="right" label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="editKey(scope.row)">编辑</el-button>
                                    <!-- <el-button type="text" size="small">删除</el-button> -->
                                </template>
                            </el-table-column>
                        </el-table>

                        
                    </div>

                    <!-- <div style="margin-top: 12px; float: right">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[100, 200, 300, 400]"
                            :page-size="100"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="400">
                        </el-pagination>
                    </div> -->
                </div>
            </div>
        </div>

        <el-dialog :title="dictionTitle" width="40%" :visible.sync="dictionlog">
            <el-form :model="dictionForm" ref="dictionForm" style="width: 80%; margin: 20px auto 0;" :rules="rules1" label-width="80px">
                <el-form-item label="字典名称" prop="dictText">
                    <el-input v-model="dictionForm.dictText" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典编码" prop="dictCode">
                    <el-input v-model="dictionForm.dictCode" autocomplete="off"></el-input>
                </el-form-item>
            
<!--                 
                <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                    <el-radio v-model="dictionForm.status" label="1">启用</el-radio>
                    <el-radio v-model="dictionForm.status" label="0">停用</el-radio>
                </el-form-item> -->
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dictionlog = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="dialogTitle" width="40%" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="cdForm" :rules="rules2" style="width: 80%; margin: 20px auto 0;" label-width="100px">
                <el-form-item label="字典值编码" prop="dictValueCode">
                    <el-input v-model="form.dictValueCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="字典值名称" prop="dictValueText">
                    <el-input v-model="form.dictValueText" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="form.sort" controls-position="right" :min="1" :max="100000"></el-input-number>
                    <!-- <el-input v-model="form.sort" autocomplete="off"></el-input> -->
                </el-form-item>
               <!-- <el-form-item label="字典描述" :label-width="formLabelWidth">
                    <el-input v-model="form.orderNum" autocomplete="off"></el-input>
                </el-form-item> -->
                
                <!-- <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                    <el-radio v-model="form.status" label="1">启用</el-radio>
                    <el-radio v-model="form.status" label="0">停用</el-radio>
                </el-form-item> -->
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureOk">确 定</el-button>
            </div>
      </el-dialog>
    </div>
</template>

<script>
// import json from '../../assets/json/icon.json'
export default {
    data() {
      return {
        filterText: '',
        dialogTitle: '新增菜单',
        dictionTitle: '新增字典',
        dictionlog: false,
        dialogFormVisible: false,
        dictionForm: {},
        form: {},
        isShow: false,
        chooseDiction: {},
        tableData: [],
        leftData: [],
        allLeftData:[],
        data: [],
        rules1: {
            dictText: {required: true, message: '请输入字典名称', trigger: 'blur'},
            dictCode: {required: true, message: '请输入字典编码', trigger: 'blur'},
        },
        rules2: {
            dictValueCode: {required: true, message: '请输入字典值编码', trigger: 'blur'},
            dictValueText: {required: true, message: '请输入字典值名称', trigger: 'blur'},
            sort: {required: true, message: '请输入排序', trigger: 'blur'},
        }
      };
    },

    mounted(){
        this.searchLeft();
        if (window.innerHeight){
            var winHeight = window.innerHeight;
        }
        else if ((document.body) && (document.body.clientHeight)){
            var winHeight = document.body.clientHeight;
        }
        document.getElementById("dataDictionary").style.minHeight = (winHeight-90)+'px';
    },

    methods: {
        searchLeft(){
            this.qa.dictionList({}).then(res => {
                if(res.respCode == "0000") {
                    this.leftData = res.data;
                    this.allLeftData = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        searchRight(){
            this.qa.queryDicById({dictId: this.chooseDiction.id}).then(res => {
                if(res.respCode == "0000") {
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        filterTextChangeHandler(){
            this.leftData = this.allLeftData.filter((_data)=>{
                if(this.filterText=="")return true;
                return (_data.dictText.indexOf(this.filterText)!=-1);
            });
            
        },
        //点击字典
        handleClick(row){
            this.chooseDiction = row;
            this.searchRight();
            this.isShow = true;
        },
        //新增字典
        addDiction(){
            this.dictionTitle = '新增字典';
            this.dictionForm = {
                "dictCode":"",
                "dictText":""
            };
            this.dictionlog = true;
        },
        editDiction(){
            this.dictionTitle = '修改字典';
            this.dictionForm = {
                "id": this.chooseDiction.id,
                "dictCode": this.chooseDiction.dictCode,
                "dictText": this.chooseDiction.dictText
            };
            this.dictionlog = true;
        },
        add(){
            this.$refs.dictionForm.validate((valid) => {
                if (valid) {    
                    if(this.dictionTitle == "新增字典") {
                        this.qa.addDiction(this.dictionForm).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                    message: '新增字典成功！',
                                    type: 'success'
                                });
                                this.dictionlog = false;
                                this.searchLeft();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.qa.editDiction(this.dictionForm).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                    message: '修改字典成功！',
                                    type: 'success'
                                });
                                this.dictionlog = false;
                                this.searchLeft();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    return false;
                }
            })
            
        },

        addKey(){
            this.dialogTitle = "新增字典值";
            this.form = {
                "dictId": this.chooseDiction.id,
                "dictValueCode": "",
                "dictValueText": "",
                "sort": ""
            };
            this.dialogFormVisible = true;

        },
        editKey(row){
            this.dialogTitle = "编辑字典值";
            this.form = {
                // "dictId": this.chooseDiction.id,
                "id": row.id,
                "dictValueCode": row.dictValueCode,
                "dictValueText": row.dictValueText,
                "sort": row.sort
            };
            this.dialogFormVisible = true;
        },
        sureOk(){
            this.$refs.cdForm.validate((valid) => {
                if (valid) {
                    if(this.dialogTitle == "新增字典值") {
                        this.qa.addDictValue(this.form).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                        message: '新增字典值成功！',
                                        type: 'success'
                                    });
                                this.dialogFormVisible = false;
                                this.searchRight();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.qa.editDictValue(this.form).then(res => {
                            if(res.respCode == "0000") {
                                this.$message({
                                        message: '编辑字典值成功！',
                                        type: 'success'
                                    });
                                this.dialogFormVisible = false;
                                this.searchRight();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                } else {
                    return false;
                }
            })
            
            
        },
      changeStatus(value){
          this.qa.dicSwitch({id: value.id}).then(res => {
              if(res.respCode == "0000") {
                        this.searchRight();
                    } else {
                        this.$message.error(res.msg);
                    }
          })
          
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      }
    },

}
</script>

<style scoped>
.menu >>> .el-input .el-input__suffix .el-input__icon{
    line-height: 32px;
  }
  .menu_title {
    width: 100%;
    height: 68px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .menu_content {
      width: 100%;
      background: #fff;
      margin-top: 12px;
  }
  .menu_content_title {
      height: 50px;
      border-bottom: 1px solid #EFEFEF;
      position: relative;

  }
  .menu_content_title >h4 {
    position: absolute;
    color: #1D1E1F;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    left: 24px;
    bottom: 0;
  }
  .menu_content_content {
      display: flex;
  }
  .menu_left {
      padding: 10px 24px;
      width: 260px;
      border-right: 1px solid #EFEFEF;
  }
  .menu_left >>> .el-input__inner {
      height: 32px;
  }
  .menu_right {
      flex: 1;
      flex-shrink: 0;
      padding: 10px 24px;
  }
  .menu_right >h6 {
      font-size: 16px;
      color: #1D1E1F;
      margin-top: 30px;
  }
  .menu_right >h6 a {
      font-size: 14px;
      color: #1991FC;
      font-weight: 400;
      margin-left: 24px;
      cursor: pointer;
  }
  .menu_right >p {
      margin-top: 10px;
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
  }
  .menu_right >p >span {
      margin-right: 20px;
  }
  .dictionary_list {
      font-size: 14px;
      cursor: pointer;
      color: #1D1E1F;
      margin-bottom: 12px;
  }
  .dictionary_list >span {
      margin-right: 10px;
  }
  .dictionary_list:hover {
      color: #1991FC;
  }
  .chooseDic {
      color: #1991FC;
  }
</style>